import React, { useState, useEffect } from 'react';
import { Grid, Paper, TextField } from '@material-ui/core';
import DefaultTable from '../../common/DefaultTable';
import { getBudgetDetails } from '../../../services/budgetService';
import { useParams } from 'react-router-dom';
import { json2array } from '../../../utils/json2array';
import _ from "lodash";
function BudgetDetails() {
	let params = useParams();
	const { budgetId } = params;

	const addThousandSeparator = (rowData, fieldName) => {
		const field = _.get(rowData, fieldName);
		if(field === undefined) return '';
		const parsedNumber = field.split(".");
		return Number(parseFloat(parsedNumber[0]).toFixed(0)).toLocaleString() + '.' + parsedNumber[1];
	}

	const columns = [
		{
			title: 'Nazwa krótka',
			field: 'short_name',
			short_name: ''
		},
		{
			title: 'Nazwa',
			field: 'name',
			short_name: ''
		},
		{
			title: 'Kwota sprzedaży',
			field: 'offer_data.selling_price',
			short_name: 'selling_price',
			render: (rowData) => addThousandSeparator(rowData, 'offer_data.selling_price'),
		},
		{
			title: 'Maksymalny koszt wykonania prac',
			field: 'offer_data.max_price',
			short_name: 'max_price',
			render: (rowData) => addThousandSeparator(rowData, 'offer_data.max_price'),
		},
		{
			title: 'Podwykonawca',
			field: 'order_stage.subcontractor_name',
			short_name: 'subcontractor_name'
		},
		{
			title: 'Kwota zlecenia / koszt robót',
			field: 'order_stage.order_price',
			short_name: 'order_price',
			render: (rowData) => addThousandSeparator(rowData, 'order_stage.order_price'),
		},
		{
			title: 'Wynik na zleceniu',
			field: 'order_stage.profit',
			short_name: 'profit'
		},
		{
			title: 'Wynik na zleceniu %',
			field: 'order_stage.profit_percent',
			short_name: 'profit_percent'
		},
		{
			title: 'Postęp prac',
			field: 'advanced.work_progress',
			short_name: 'work_progress'
		},
		{
			title: 'Szacowane zaawansowanie kosztów',
			field: 'advanced.estimate_costs_amount',
			short_name: 'estimate_costs_amount'
		},
		{
			title: 'Szacowane zaawansowanie sprzedaży',
			field: 'advanced.estimate_sell_amount',
			short_name: 'estimate_sell_amount'
		},
		{
			title: 'Sprzedaż faktyczna',
			field: 'state.sell_amount',
			short_name: 'sell_amount'
		},
		{
			title: 'Odchyłka kwoty sprzedaży',
			field: 'state.dist_sell_amount',
			short_name: 'dist_sell_amount'
		},
		{
			title: 'Koszty faktyczne',
			field: 'state.costs_amount',
			short_name: 'costs_amount',
			render: (rowData) => addThousandSeparator(rowData, 'state.costs_amount'),
		},
		{
			title: 'Odchyłka kwoty kosztów',
			field: 'state.dist_costs_amount',
			short_name: 'dist_costs_amount',
			render: (rowData) => addThousandSeparator(rowData, 'state.dist_costs_amount'),
		}
	];

	const [ budgetDetails, setBudgetDetails ] = useState([]);
	const [ budgetBasicData, setBudgetBasicData ] = useState({});


	const fetchBudgetDetails = async () => {
		try {
			const response = await getBudgetDetails(budgetId);
			const fetchedBudgetDetails = response.data;
			const arrayBudgetCategories = json2array(fetchedBudgetDetails.categories);
			
			const summary = arrayBudgetCategories.shift();
			const columnFields = columns.map(x => x.field);
			const summaryCorrected = {}
			Object.keys(summary).forEach(key => {
				let column = columns.find(column => column.short_name === key)
				if (column){
					const fullName = column.field
					summaryCorrected[fullName] = summary[key];
				}

			});
			summaryCorrected["name"] = "SUMA";
			arrayBudgetCategories.unshift(summaryCorrected);
			setBudgetDetails(arrayBudgetCategories);
			setBudgetBasicData(fetchedBudgetDetails.budget);
		} catch (error) {
			console.log(error);
		}
	};

	const toNumeric = (data) => {
		if(data === null) return;
		var parsed;
		if(typeof(data) == "object"){
			parsed = parseFloat(...data);
		} else if(typeof(data) == "string") {
			parsed = parseFloat(data);
		}
		if(!isNaN(parsed)) {
			return parsed;
		}
		return data;
	  }

	  const isNumeric = (str) => {
		if (typeof str != "string") return false 
		return !isNaN(str) && 
			   !isNaN(parseFloat(str)) 
	  }
	  
	useEffect(() => {
		fetchBudgetDetails();
	}, []);

	return (
		<Grid container spacing={2} direction="column" style={{display: "block"}}>
			<Grid item >
				<Paper style={{ padding: 20 }}>
					<Grid container item spacing={2} justify="center" alignItems="center" direction="row">
						<Grid item xs={2}>
							<TextField
								id="standard-read-only-input"
								label="Budżet"
								value={budgetBasicData.name || ''}
								InputProps={{
									readOnly: true
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								id="standard-read-only-input"
								label="Kierownik budowy"
								value={budgetBasicData.project_manager || ''}
								InputProps={{
									readOnly: true
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								id="standard-read-only-input"
								label="Kierownik kontraktu"
								value={budgetBasicData.manager || ''}
								InputProps={{
									readOnly: true
								}}
								fullWidth
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								id="standard-read-only-input"
								label="Budowa"
								value={budgetBasicData.project || ''}
								InputProps={{
									readOnly: true
								}}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item >
					<DefaultTable 
						columns={columns} 
						data={budgetDetails}
						options={{
								cellStyle: (data) => {
									if(isNumeric(data)){
										return {
											fontSize: 10,
											padding: 1,
											size: 'small',
											'text-align': 'right',
											'padding-right': 26
										}
									}else {
										return {
											fontSize: 10,
											padding: 1,
											size: 'small',
											'text-align': 'left',
											'padding-right': 26
										}

									}
								},
								headerStyle: {
									fontSize: 10,
									position: 'sticky',
									top: 0,
									padding: 1,
									'text-align': 'right'

								},
								rowStyle : (e) => {
									if (e.name === "SUMA") {
										return {
											"background-color": "#C5D5D7",
											"font-weight": "bold",
											"padding-top": 10
										}
									}
								},
								display: 'block',
								pageSize: 20,
								pageSizeOptions: [ 10, 15, 20, 50, 100 ],
							}}
					/>
			</Grid>
		</Grid>
	);
}

export default BudgetDetails;
